import { useState,useEffect } from 'react';
import { useTheme, styled } from '@mui/material/styles';

import {
  Button,
  Box,
  DialogTitle,
  DialogContent,
  Divider,
  IconButton,
  Stack,
  Typography
} from '@mui/material';
import { alpha } from "@mui/material";

import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc'
import timezone from 'dayjs/plugin/timezone'

// Extend with plugins
dayjs.extend(utc)
dayjs.extend(timezone);

const EST = 'America/New_York';

import CloseIcon from '@mui/icons-material/Close';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';

import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { PickersDay } from "@mui/x-date-pickers/PickersDay";
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';

import { ContentLock } from '../../../../components/content-lock';
import { ResponsiveDialog } from '../../../../components/responsive';
import { DefaultTooltip } from '../../../../components/buttons';


export function PredictionsPopup(
    {title,open,setOpen,selectedDate,setSelectedDate,runDisabled,contentLocked,handleRunPredictions,
    datesWithEvents,fetchValidDates,EventDisplay}
){
    // title - Can be a component or text

    const theme = useTheme();

    const handleClose = () => {
        setOpen(false);
    };

    const modalZIndexPlusOne = theme.zIndex.modal + 1;
    const closeButtonZIndex = modalZIndexPlusOne + 1;

    return (
        <>
          <ResponsiveDialog
            onClose={handleClose}
            open={open}
          >
            <DialogTitle sx={{ p: 2 }}>
              {title}
            </DialogTitle>
            <IconButton
              onClick={handleClose}
              sx={{
                position: 'absolute',
                right: 8,
                top: 8,
                color: theme.palette.primary.main,
                zIndex: closeButtonZIndex
              }}
            >
              <CloseIcon />
            </IconButton>
            <DialogContent>
              <ContentLock open={contentLocked} zIndex={modalZIndexPlusOne}/>
              <Stack direction="column" divider={<Divider orientation="vertical" flexItem />} 
              spacing={{xs:0.5,md: 2}} justifyContent="space-around" alignItems="center"
              >
                <PredictionsDatePickerStack
                    selectedDate={selectedDate}
                    setSelectedDate={setSelectedDate}
                    datesWithEvents={datesWithEvents}
                    fetchValidDates={fetchValidDates}
                />
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    flexDirection: 'column',
                    width: '100%'
                  }}
                >
                  <Stack direction="row" alignItems="center" spacing={0.5} sx={{ mb: 1 }}>
                    <Typography>
                      Events for the Selected Date
                    </Typography>
                    <DefaultTooltip 
                    text={'Dates are in EST to preserve game day. Highlighted on the calendar are the dates with games you are able to predict. \
                      At the beginning of the season, some teams or players have insufficient data to generate accurate predictions. \
                      So you might notice "missing games" until we progress into the season a little bit further.\
                      '} 
                    />
                  </Stack>

                  <Box sx={{
                    flexGrow: 1, borderRadius: 2,
                    width: "100%", height: 300, overflowY: "scroll"
                  }}>
                    <Stack direction="column" spacing={1} alignItems="center" height="100%">
                        {EventDisplay}
                    </Stack>
                  </Box>
    
                  <Button 
                    variant="contained" 
                    autoFocus
                    disabled={runDisabled} 
                    onClick={handleRunPredictions}
                    sx={{ mt: 2 }}
                  >
                    Predict
                  </Button>
                </Box>
              </Stack>
            </DialogContent>
          </ResponsiveDialog>
        </>
      )
}

function PredictionsDatePickerStack(
    { selectedDate, setSelectedDate,datesWithEvents, fetchValidDates }
) {

    return (
        <Stack direction="row">
            <IconButton onClick={() => {setSelectedDate(selectedDate.subtract(1,'day'))}}
            disableRipple
            >
                <KeyboardArrowLeftIcon size="small"/>
            </IconButton>

            <PredictionsDatePicker 
            selectedDate={selectedDate} 
            setSelectedDate={setSelectedDate} 
            datesWithEvents={datesWithEvents}
            fetchValidDates={fetchValidDates}
            />

            <IconButton onClick={() => {setSelectedDate(selectedDate.add(1,'day'))}} 
            disableRipple
            >
                <KeyboardArrowRightIcon size="small"/>
            </IconButton>
    </Stack>
    );

}

const HighlightedDay = styled(PickersDay)(({ theme }) => ({
    "&.Mui-selected": {
      backgroundColor: alpha(theme.palette.primary.main,0.6),
      color: theme.palette.primary.contrastText,
    },
  }));
  
  //higlight the dates in the array
  const ServerDay = (props) => {
    const { datesWithEvents = [], day, outsideCurrentMonth, ...other } = props;
  
    const isSelected =
      !props.outsideCurrentMonth &&
      datesWithEvents.includes(day.format("YYYYMMDD"));
  
    return (
      <HighlightedDay
        {...other}
        outsideCurrentMonth={outsideCurrentMonth}
        day={day}
        selected={isSelected}
      />
    );
};

function PredictionsDatePicker({ selectedDate, setSelectedDate, datesWithEvents, fetchValidDates }) {

    const theme = useTheme();
    const today = dayjs().tz(EST);

    // Track years we have fetched to prevent refetches and the array of dates
    // from growing too large
    const [yearsFetched,setYearsFetched] = useState([today.year()]);

    const minDate = dayjs('2014-01-01');
    const maxDate = today.add(1,'month');


    // Load valid dates for the current year when the component renders
    useEffect(() => {
        fetchValidDates(today);
    }, []);

    const handleChange = (newDate) => {
        handleMonthOrYearChange(newDate);
        setSelectedDate(newDate);
    }

    const handleMonthOrYearChange = (newDate) => {

        const newYear = newDate.year();
        if (newYear !== selectedDate.year() && yearsFetched.indexOf(newYear) < 0){
            // This is a year change that we haven't fetched yet. 
            // Have to manually process this because the built in props 
            // such as onYearChange only trigger on acceptance of a date
            fetchValidDates(newDate);

            // Add to list of fetched years
            setYearsFetched(yearsFetched.concat([newYear]))
        }
    }

    return (
        <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
                // The timezone prop of DatePicker has bugs and does not
                // actually work to set the timezone of the date. There are
                // multiple open PRs about this.
                //
                // But it is not a big deal, because the timezone of the value
                // is what is used for all new dates. So if we explicitly set the
                // timezone of the initial selectedDate to EST, then EST is enforced
                // for all new dates.

                value={selectedDate}
                onChange={(newDate) => handleChange(newDate)}
                
                minDate={minDate}
                maxDate={maxDate}

                // When to switch between desktop mode and mobile mode
                desktopModeMediaQuery={theme.breakpoints.up('sm')}
                
                // Highlight dates that have a game/event
                onYearChange={(newDate) => handleMonthOrYearChange(newDate)}
                onMonthChange={(newDate) => handleMonthOrYearChange(newDate)}
                slots={{
                    day: ServerDay,
                  }}
                slotProps={{
                    // Style highlighted days
                    day: {
                      datesWithEvents,
                    }
                }}
            />
        </LocalizationProvider>
    );
  }