import { useState } from 'react';
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Box,
    Card,
    CardContent,
    IconButton,
    Skeleton,
    Stack,
    Typography,
} from '@mui/material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import CircleIcon from '@mui/icons-material/Circle';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import LoadingButton from '@mui/lab/LoadingButton';
import DeleteIcon from '@mui/icons-material/Delete';

import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc'
import timezone from 'dayjs/plugin/timezone'

// Extend with plugins
dayjs.extend(utc)
dayjs.extend(timezone);

const EST = 'America/New_York';

import { CommonBetDescription, MatchupTyporaphy } from '../../../../components/tracking';
import { calculateProfit } from './common';
import { USD, USD_noSign } from '../../../../utils/numbers';
import { capitalize } from '../../../../utils/general';
import { betDeletionPlacedTimeDeltaMinutes, betDeletionStartTimeDeltaMinutes } from '../../../../config/tracking';
import { teamBetDeleteRequest } from '../../../../hooks/api/team';
import { playerBetDeleteRequest } from '../../../../hooks/api/player';
import { ErrorSnackbar, SuccessSnackbar } from '../../../../components/snackbars';

function BetSummary({bet}){

    let price = bet.price;
    let point = bet.point;
    let sportsbook = bet.sportsbook;

    if (price > 0) {
        price = "+" + price;
    }

    let betTypeString=point;
    switch (bet.market) {
        case 'spreads':
            if (point > 0) {
                point = "+" + point;
            }
            else if (point === 0) {
                point = "PK";
            };
            betTypeString = `${bet[bet.pick]['abbreviation']} ${point}`;
            break;
        default:
            betTypeString = `${capitalize(bet.pick)} ${point}`
            break;
    }

    return (
        <Stack 
        direction="column"
        spacing={0.75}
        alignItems="flex-start" 
        justifyContent="center"
        width="100%"
        >
            <CommonBetDescription
            summary={betTypeString}
            price={price}
            sportsbook={sportsbook}
            />
            <MatchupTyporaphy
            league={bet.league}
            prop={bet.prop} // Only used for player, don't worry about it being null for team
            matchup={bet} // Bet has the same fields as matchup when it has metadata
            stackPropOverrides={{
                direction: 'column',
                alignItems: 'flex-start'
            }}
            />
        </Stack>
    )
}

function ResultDisplay({stake,price,result}){

    let Icon = CircleIcon;
    let color = 'text.secondary';
    let content = (<></>);
    switch (result) {
        case 'win':
            Icon = CheckCircleIcon;
            color = 'secondary.main';
            content = <Typography color={color}>+{USD.format(calculateProfit(stake,price))}</Typography>;
            break;
        case 'loss':
            Icon = CancelIcon;
            color = 'error.dark';
            content = <Typography color={color}>{USD.format(stake*-1)}</Typography>;
            break;
        case 'push':
        case 'void':
            Icon = CircleIcon;
            content = <Typography>{result.toUpperCase()}</Typography>;
            break;
        default:
            break;
    }

    return (
        <Stack direction="column" justifyContent="center" alignItems="center" spacing={1} minWidth={80}>
            {result !== 'incomplete' ?
             <>
             <Icon
                fontSize="medium"
                sx={{
                    color: color
                }}
            />
            {content}
             </>
             :
             <>
             <Typography>${USD_noSign.format(stake)}</Typography>
             </>
             }
        </Stack>
    )
}

function TeamModelPrediction({away_display_name_abbreviated,away_prediction,home_display_name_abbreviated,home_prediction}){

    return (
        <Typography variant="subtitle2" sx={{color: 'text.secondary'}}>
            {away_display_name_abbreviated} {away_prediction} - {home_display_name_abbreviated} {home_prediction}
        </Typography>
    )
}

function PlayerModelPrediction({prediction,units}){

    return (
        <Typography variant="subtitle2" sx={{color: 'text.secondary'}}>
            {prediction} {units}
        </Typography>
    )
}

function ModelPrediction({bet}){

    const isTeamBet = bet.type === 'team';

    return (
        <Box component="div" sx={{width: '100%'}}>
        <Accordion 
        disableGutters
        square={true}
        sx={{
            width: '100%',
            borderRadius: 2, // Manually set border radius after disabling rounding using 'square' prop above
            position: 'inherit', // Hack to remove this weird line above the accordion. Only works when
            // accordion is within a Box/Grid (display: block), display: flex causes issues
        }}>
            <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            >
            Model Prediction
            </AccordionSummary>
            {/* <Divider orientation="horizontal" flexItem /> */}
            <AccordionDetails>
                <Stack direction="column" spacing={1} width="100%">
                    <Typography variant="subtitle2" sx={{color: 'text.secondary'}}>Model: {bet.model_name}</Typography>
                    <Stack direction="row" alignItems="center" spacing={0.3}>
                    <Typography variant="subtitle2" sx={{color: 'text.secondary'}}>
                        Prediction:
                    </Typography>
                    {isTeamBet ? 
                    <TeamModelPrediction
                    away_display_name_abbreviated={bet.away.abbreviation}
                    away_prediction={bet.away.prediction}
                    home_display_name_abbreviated={bet.home.abbreviation}
                    home_prediction={bet.home.prediction}
                    />
                    :
                    <PlayerModelPrediction
                    prediction={bet.prediction}
                    units={bet.units}
                    />
                    }
                    </Stack>
                </Stack>
            </AccordionDetails>
        </Accordion>
        </Box>
        
    )
}

export function LatestBets({allBets,allBetsLoading}){

    const numLatestBetsToLoad = 5;
        
    // Slicing beyond the length of an array is a safe operation, so no need to add protections here
    const [latestBets,setLatestBets] = useState(allBets.slice(0,numLatestBetsToLoad));
    const [latestBetsLoading,setLatestBetsLoading] = useState(false);
    const [deletedBetIds,setDeletedBetIds] = useState([]);

    const defaultErrorSnackbarMessage = 'Failed to delete bet';
    const [errorSnackbarOpen,setErrorSnackbarOpen] = useState(false);
    const [errorSnackbarMessage,setErrorSnackbarMessage] = useState(defaultErrorSnackbarMessage);
    const [successSnackbarOpen,setSuccessSnackbarOpen] = useState(false);

    const handleLoadMoreClicked = () => {
        setLatestBetsLoading(true);

        // Fetch the next N bets
        const nextLatestBetsEndIndex = latestBets.length + deletedBetIds.length + numLatestBetsToLoad;

        // Skip deleted bets until a reload is done
        setLatestBets(allBets.slice(0,nextLatestBetsEndIndex).filter((latestBet) => !deletedBetIds.includes(latestBet['_id'])));

        setLatestBetsLoading(false);
        
    }

    const handleError = (error) => {

        setSuccessSnackbarOpen(false);

        let invalidTiming = false;
        if (error.response) {
            if (error.response.status === 406) {
                // Bet event start time and placed time not valid
                // for deletion
                invalidTiming = true;
            }
        }
        setErrorSnackbarMessage(invalidTiming ? 
            `Bets can only be deleted within ${betDeletionPlacedTimeDeltaMinutes} min of being placed and 
            ${betDeletionStartTimeDeltaMinutes} min before the game start time`
            :
            defaultErrorSnackbarMessage
        )

        setErrorSnackbarOpen(true);
        setLatestBetsLoading(false);
    }

    const deleteBet = (bet) => {
        setLatestBetsLoading(true);

        setErrorSnackbarOpen(false);

        const params = {
            'bet_id' : bet['_id']
        }

        const handleResponse = (response) => {
            // Defined as function in function so it has knowledge of bet ID
            //
            // Reload latest bets. Only incomplete bets can be deleted so they will
            // only effect latest bets and not any profit calculations or graphics.

            // Remove from latest bets and record _id that was deleted to ignore it
            // and prevent having to reload or refetch
            setLatestBets(latestBets.filter((latestBet) => latestBet['_id'] !== bet['_id']));
            setDeletedBetIds(deletedBetIds => [...deletedBetIds,bet['_id']]);

            setSuccessSnackbarOpen(true);
            setLatestBetsLoading(false);
    
        }

        // Make API call to delete bet
        if (bet.type === 'team') {
            teamBetDeleteRequest(params,handleResponse,handleError);
        }
        else {
            // Player
            playerBetDeleteRequest(params,handleResponse,handleError);
        }
    }

    return (
        <Stack direction="column" spacing={1} width="100%" height='100%' sx={{overflowY: 'scroll'}}>
            {allBetsLoading ?
            <Skeleton variant="rounded" width="100%" height="100%" />
            :
            <>
            {latestBets.map((bet,index) => (
                <Stack 
                key={index} 
                direction="column" 
                spacing={1} 
                width="100%"
                alignItems="flex-start" 
                justifyContent="center"
                sx={{ borderRadius: 3, py: 2, px: 2,backgroundColor: 'background.default',position: 'relative'}}
                >
                    <Stack 
                    direction="row"
                    spacing={2}
                    width="100%"
                    alignItems="center" 
                    justifyContent="space-between"
                    >
                        <BetSummary bet={bet} />
                        <ResultDisplay
                        stake={bet.stake}
                        price={bet.price}
                        result={bet.result}
                        />
                    </Stack>
                    <ModelPrediction 
                    bet={bet}
                    />

                    {/* 
                    dayjs automatically converts times to local time so no need for explicit
                    definitions of timezones. Just let them be converted to local time and they
                    will be the same and can be compared.
                    */}
                    {dayjs(bet.start_time).diff(dayjs(),'minute') > betDeletionStartTimeDeltaMinutes 
                    && 
                    dayjs().diff(dayjs(bet.placed_time),'minute') < betDeletionPlacedTimeDeltaMinutes 
                    &&
                    <IconButton
                    size="small"
                    onClick={()=> deleteBet(bet)}
                    sx={{
                        position: 'absolute',
                        top: -4,
                        right: 2
                    }}
                    >
                        <DeleteIcon sx={{color: 'text.secondary'}} />
                    </IconButton>
                    }
                </Stack>
            ))}
            {allBets.length !== latestBets.length + deletedBetIds.length &&
            <Stack alignItems="center">
                <LoadingButton
                loading={latestBetsLoading}
                onClick={() => handleLoadMoreClicked()}
                variant="contained"
                >
                    Load More
                </LoadingButton>
            </Stack>
            }
            {allBets.length === 0 &&
            <Card sx={{height: '100%',width: '100%'}}>
                <CardContent 
                sx={{
                    height: '100%',
                    "&:last-child": {
                    paddingBottom: 0
                    },
                    p: 0,
                }}>
                    <Stack 
                    alignItems="center"
                    justifyContent="center"
                    width="100%" 
                    height="100%"
                    sx={{py: 2}}
                    >
                        <Typography>No Tracked Bets</Typography>
                    </Stack>
                </CardContent>
            </Card>
            }
            </>
            }
            <SuccessSnackbar
            open={successSnackbarOpen}
            setOpen={setSuccessSnackbarOpen}
            message="Successfully deleted tracked bet"
            />
            <ErrorSnackbar 
            open={errorSnackbarOpen}
            setOpen={setErrorSnackbarOpen}
            message={errorSnackbarMessage}
            />
        </Stack>

    )
}