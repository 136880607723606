
const images = require.context('../../assets/team-logos', true, /\.png$/);

/*
    Input:
        team (string): location + nickname

    Logo filename Rules:
     1. location_nickname.png
     2. All lower case
     3. All spaces replaced with underscore
     4. Remove special characters
*/
export function TeamLogo({team,league,width=25}){

    // Try to load the image using require() but handle if it does not exist
    let logoSrc;
    try {
        // Replace spaces with underscores
        let formattedTeam = team.toLowerCase().replace(/\s+/g,"_");

        // Remove special characters
        // Example: miami_(oh)_redhawks -> miami_oh_redhawks
        formattedTeam = formattedTeam.replace(/[&\/\\#,+()$~%.'":*?<>{}]/g, '');

        const logoFile = "./"+league+"/"+formattedTeam+".png";
        
        logoSrc = images(logoFile);
    }
    catch {
        logoSrc = null;
    }

    return (
        <>
            <img src={logoSrc} 
            alt=""
            width={width}
            overflow="clip"
            />
        </>
    );
}