import { useEffect, useState } from 'react';
import { styled,useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useNavigate } from 'react-router-dom';
import {
    Button,
    Drawer,
    IconButton,
    InputAdornment,
    FormControl,
    Stack,
    TextField,
    Typography
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import LoadingButton from '@mui/lab/LoadingButton';

import {
    MatchupTyporaphy,
    CommonBetDescription
} from '../../../../components/tracking';
import SwipeableDrawer from '@mui/material/SwipeableDrawer';
import { calculateProfit } from '../../../profile/components/tracking/common';
import { capitalize } from '../../../../utils/general';
import { ErrorSnackbar, SuccessSnackbar } from '../../../../components/snackbars';

import { teamBetsPostRequest } from '../../../../hooks/api/team';
import { playerBetsPostRequest } from '../../../../hooks/api/player';

import { USD_noSign } from '../../../../utils/numbers';

const getChosenBetDetails = (bet) => {

    const isTeamBet = bet.matchup.type === 'team' ? true : false;

    let sportsbook = '';
    let price = -100;
    let point = 1;
    
    let chosenBetDetails = {};
    if (bet.sportsbook === 'best'){
        // Get info from best odds
        chosenBetDetails = isTeamBet ? bet.best_odds[bet.market][bet.pick] : bet.best_odds[bet.pick];
        sportsbook = chosenBetDetails.sportsbooks[0];
    } else {
        chosenBetDetails = isTeamBet ? bet.sportsbooks[bet.sportsbook][bet.market][bet.pick] : bet.sportsbooks[bet.sportsbook][bet.pick];
        sportsbook = bet.sportsbook;
    }

    price = chosenBetDetails.price;
    point = chosenBetDetails.point;
    
    return {sportsbook,price,point};
}

export function BetSummary({bet}){

    let {price,point,sportsbook} = getChosenBetDetails(bet);

    if (price > 0) {
        price = "+" + price;
    }

    let summary=point;
    switch (bet.market) {
        case 'spreads':
            if (point > 0) {
                point = "+" + point;
            }
            else if (point === 0) {
                point = "PK";
            };
            summary = `${bet.matchup[bet.pick]['abbreviation']} ${point}`;
            break;
        default:
            summary = `${capitalize(bet.pick)} ${point}`
            break;
    }

    return (
        <Stack 
        direction="column"
        spacing={0.75}
        alignItems="flex-start" 
        justifyContent="center"
        width="100%"
        >
            <CommonBetDescription
            summary={summary}
            price={price}
            sportsbook={sportsbook}
            />
            <MatchupTyporaphy 
            league={bet.league}
            prop={bet.prop} // Only used for player, don't worry about it being null for team
            matchup={bet.matchup} />
        </Stack>
    )
}

function StakeProfitDisplay({index,pendingBets,setPendingBets,renderToggle,setRenderToggle}){

    const {price} = getChosenBetDetails(pendingBets[index]);

    const MIN_STAKE = 0;
    const MAX_STAKE = 1000;

    const handleStakeChange = (event) => {
        event.preventDefault();

        let tempPendingBets = pendingBets;
        if (event.target.value){
            let tempStake = Math.round(event.target.value * 100) / 100;
            tempStake = tempStake > MAX_STAKE ? MAX_STAKE : tempStake;
            tempStake = tempStake < MIN_STAKE ? MIN_STAKE : tempStake;
            tempPendingBets[index].stake = tempStake;
        }
        else {
            tempPendingBets[index].stake = event.target.value;
            
        } 
        setPendingBets(tempPendingBets);
        setRenderToggle(!renderToggle);    
    }

    return (
        <Stack direction="row" spacing={2} width="100%">
            <FormControl fullWidth sx={{ m: 1 }}>
                <TextField
                    id={`stake-${index}`}
                    label="Stake"
                    value={pendingBets[index].stake}
                    onChange={(event) => handleStakeChange(event,index)}
                    slotProps={{
                        input: {
                            startAdornment: <InputAdornment position="start">$</InputAdornment>,
                            type: "number"
                        }
                    }}
                    size="small"
                />
            </FormControl>
            <FormControl fullWidth sx={{ m: 1 }}>
                <TextField
                    id={`profit-${index}`}
                    label="Profit"
                    slotProps={{
                        input: {
                            startAdornment: <InputAdornment position="start">$</InputAdornment>,
                            readOnly: true
                        }
                    }}
                    value={pendingBets[index].stake ? USD_noSign.format(calculateProfit(pendingBets[index].stake,price)) : 0}
                    size="small"
                />
            </FormControl>
        </Stack>
    )
}

function BetDisplay({pendingBets,setPendingBets,renderToggle,setRenderToggle}){

    const removePendingBet = (index) => {
        let tempPendingBets = pendingBets.slice();
        tempPendingBets.splice(index,1);
        setPendingBets(tempPendingBets);
        setRenderToggle(!renderToggle);
    }

    return (
        <Stack direction="column" spacing={2} width="100%" height="100%" sx={{ overflowY: 'scroll'}}>
            {pendingBets.length !==0 ?
            <>
            {pendingBets.map((bet,index) => (
                <Stack 
                key={index} 
                direction="column" 
                spacing={1} 
                width="100%"
                alignItems="flex-start" 
                justifyContent="center"
                sx={{ borderRadius: 3, py: 2, px: 2,backgroundColor: 'background.default',position: 'relative'}}
                >
                    <Stack 
                    direction="column"
                    spacing={2}
                    width="100%"
                    alignItems="center" 
                    justifyContent="center"
                    >
                        <BetSummary bet={bet} />
                        <StakeProfitDisplay 
                        index={index} 
                        pendingBets={pendingBets}
                        setPendingBets={setPendingBets} 
                        renderToggle={renderToggle} 
                        setRenderToggle={setRenderToggle}
                        />
                    </Stack>

                    <IconButton
                    size="small"
                    onClick={()=> removePendingBet(index)}
                    sx={{
                        position: 'absolute',
                        top: -4,
                        right: 2
                    }}
                    >
                        <DeleteIcon sx={{color: 'text.secondary'}} />
                    </IconButton>
                </Stack>
            ))}
            </>
            :
            <Stack width="100%" height="100%" alignItems="center" sx={{py: 3}}>
                <Typography>Click on odds to start tracking</Typography>
            </Stack>
            }
        </Stack>
    )
}

const defaultErrorSnackbarMessage = "Failed to track bets, ensure all stakes are greater than $0";

function TrackButton(
    {pendingBets,setPendingBets,renderToggle,modelType,setSuccessSnackbarOpen,setErrorSnackbarOpen,setErrorSnackbarMessage}
){
    
    const [loading,setLoading] = useState(false);
    const [totalStake,setTotalStake] = useState(0.0);
    const [totalProfit,setTotalProfit] = useState(0.0);
    
    useEffect(()=> {
        const tempTotalStake = pendingBets.reduce((acc, obj) => acc + obj.stake, 0);
        const tempTotalProfit = pendingBets.reduce((acc, obj) => {
            const {price} = getChosenBetDetails(obj);
            return acc + (obj.stake ? calculateProfit(obj.stake,price) : 0);
        }, 0);

        setTotalStake(tempTotalStake);
        setTotalProfit(Math.round(tempTotalProfit * 100) / 100);
    },[pendingBets,renderToggle])

    const handleResponse = (response) => {
        setPendingBets([]);
        setErrorSnackbarOpen(false);
        setSuccessSnackbarOpen(true);
        setLoading(false);
    }

    const handleError = (error) => {
        setSuccessSnackbarOpen(false);

        let startTimeInPast = false;
        if (error.response) {
            if (error.response.status === 406) {
                // Bet event start time in past
                startTimeInPast = true;
            }
        }
        setErrorSnackbarMessage(startTimeInPast ? 
            'A bet you attempted to track is for an event that already started'
            :
            defaultErrorSnackbarMessage
        )

        setErrorSnackbarOpen(true);
        setLoading(false);
    }

    const handleTrackClicked = () => {
        // setLoading(true);
        if (modelType === 'team'){
            // Team
            const formattedPendingBets = pendingBets.map((bet) => {
                const {sportsbook,price,point} = getChosenBetDetails(bet);
                return {
                league: bet.league,
                event_id: bet.matchup.event_id,
                stake: bet.stake,
                sportsbook: sportsbook,
                price: price,
                point: point,
                predicted_point: bet.predicted_odds[bet.market][bet.pick]['point'],
                value: bet.predicted_odds_values[bet.market][bet.pick]['value'],
                value_bool: bet.predicted_odds_values[bet.market][bet.pick]['value_bool'],
                market: bet.market,
                pick: bet.pick,
                away_prediction: bet.matchup.away.prediction,
                home_prediction: bet.matchup.home.prediction,
                }
            });
            const jsonBody = {pending_bets : formattedPendingBets};
            // Make team api request to place the formatted bets
            teamBetsPostRequest(jsonBody,handleResponse,handleError);
        }
        else {
            // Player
            const formattedPendingBets = pendingBets.map((bet) => {
                const {sportsbook,price,point} = getChosenBetDetails(bet);
                return {
                league: bet.league,
                event_id: bet.matchup.event_id,
                player_id: bet.matchup.player.player_id,
                position: bet.matchup.player.position,
                prop: bet.prop,
                stake: bet.stake,
                sportsbook: sportsbook,
                price: price,
                point: point,
                predicted_point: bet.predicted_odds[bet.pick]['point'],
                value: bet.predicted_odds_values[bet.pick]['value'],
                value_bool: bet.predicted_odds_values[bet.pick]['value_bool'],
                market: bet.market,
                pick: bet.pick,
                prediction: bet.matchup.player.prediction,
                }
            });
            const jsonBody = {pending_bets : formattedPendingBets};
            // Make team api request to place the formatted bets
            playerBetsPostRequest(jsonBody,handleResponse,handleError);
        }
    }

    return (
        <Stack direction="column" spacing={1} width="100%" sx={{py: 1}}>
            <Stack direction="row" justifyContent="space-between">
                <Typography sx={{color: 'text.secondary'}}>Stake: ${USD_noSign.format(totalStake)}</Typography>
                <Typography sx={{color: 'text.secondary'}}>Profit: ${USD_noSign.format(totalProfit)}</Typography>
            </Stack>
            <LoadingButton
            loading={loading}
            disabled={pendingBets.length === 0}
            variant="contained"
            onClick={() => handleTrackClicked()}
            sx={{backgroundColor: 'success.dark'}} 
            fullWidth
            >
                Track
            </LoadingButton>
        </Stack>
    )
}


export function DesktopBettingDrawer({open,setOpen,pendingBets,setPendingBets,modelType}){

    const navigate = useNavigate();
    
    const handleSuccessSnackbarView = (modelType) => {
        const tab = modelType == 'team' ? 'Team Tracking' : 'Player Tracking';
        navigate('/profile',{state : {selectedTab : tab}})
    }

    // Changes to a list of objects state does not trigger a re-render so
    // this will follow the pendingBets list to trigger re-renders
    const [renderToggle,setRenderToggle] = useState(true);

    // Control snackbar display based on api call result
    const [successSnackbarOpen,setSuccessSnackbarOpen] = useState(false);
    const [errorSnackbarOpen,setErrorSnackbarOpen] = useState(false);
    const [errorSnackbarMessage,setErrorSnackbarMessage] = useState(defaultErrorSnackbarMessage);

    return (
        <>
        <Drawer
        open={open} 
        onClose={() => setOpen(false)}
        anchor="bottom"
        hideBackdrop={true}
        variant="persistent"
        ModalProps={{ disableScrollLock: true }}
        PaperProps={{
            sx: {
                width: 400,
                height: `calc(100vh - 200px)`,
                left: 'auto', // This gets the drawer to show up on the right side
                borderRadius: 3,
                backgroundColor: 'background.alternate_paper',
                px: 1,
                py: 1,
                boxShadow: 3
            }
            }}
        >
            <Stack direction="column" 
            justifyContent="center" alignItems="center" height="100%" width="100%"
            spacing={1}
            >
                <Stack direction="row" justifyContent="space-between" alignItems="center" width="100%" sx={{px: 1}}>
                    <Typography sx={{color: 'text.secondary' }}>
                        {pendingBets.length} bet{pendingBets.length !== 1 && "s"}
                    </Typography>
                    <Button onClick={() => setOpen(false)}>
                        Close
                    </Button>
                </Stack>
                <Stack 
                id="tracking-content-container"
                direction="column"
                justifyContent="space-between" 
                alignItems="center"
                height="100%" 
                width="100%"
                sx={{ pb: 1, overflow: 'auto' }}
                >
                    <BetDisplay
                    pendingBets={pendingBets}
                    setPendingBets={setPendingBets}
                    renderToggle={renderToggle}
                    setRenderToggle={setRenderToggle}
                    />
                    <TrackButton 
                    pendingBets={pendingBets} 
                    setPendingBets={setPendingBets} 
                    renderToggle={renderToggle} 
                    modelType={modelType}
                    setSuccessSnackbarOpen={setSuccessSnackbarOpen}
                    setErrorSnackbarOpen={setErrorSnackbarOpen}
                    setErrorSnackbarMessage={setErrorSnackbarMessage}
                    />
                </Stack>
            </Stack>
      </Drawer>

        <SuccessSnackbar 
        open={successSnackbarOpen} 
        setOpen={setSuccessSnackbarOpen} 
        message={"Bets tracked successfully"} 
        viewFunction={() => handleSuccessSnackbarView(modelType)}
        />
        <ErrorSnackbar 
        open={errorSnackbarOpen} 
        setOpen={setErrorSnackbarOpen} 
        message={errorSnackbarMessage} 
        />
    </>
    )
}


const drawerBleeding = 56;

const Root = styled('div')(({ theme }) => ({
    height: '100%',
    backgroundColor: 'none'
  }));

const StyledStack = styled(Stack)(({ theme }) => ({
  backgroundColor: theme.palette.background.alternate_paper,
  alignItems: 'center',
}));

const Puller = styled('div')(({ theme }) => ({
  width: 30,
  height: 6,
  backgroundColor: theme.palette.background.default,
  borderRadius: 3,
  position: 'absolute',
  top: 8,
  left: 'calc(50% - 15px)', // Keep this
}));

export function MobileBettingDrawer(props) {

    const {window,open,setOpen,pendingBets,setPendingBets,modelType} = props;
    const theme = useTheme();
    const largeScreen = useMediaQuery(theme.breakpoints.up('md'));

    // Changes to a list of objects state does not trigger a re-render so
    // this will follow the pendingBets list to trigger re-renders
    const [renderToggle,setRenderToggle] = useState(true);

    const toggleDrawer = (newOpen) => () => {
    setOpen(newOpen);
    };

    // Control snackbar display based on api call result
    const [successSnackbarOpen,setSuccessSnackbarOpen] = useState(false);
    const [errorSnackbarOpen,setErrorSnackbarOpen] = useState(false);
    const [errorSnackbarMessage,setErrorSnackbarMessage] = useState(defaultErrorSnackbarMessage);

    return (
        <>
        <Root>
        <SwipeableDrawer
        container={window}
        anchor={largeScreen ? "right" : "bottom"}
        open={open}
        onClose={toggleDrawer(false)}
        onOpen={toggleDrawer(true)}
        swipeAreaWidth={drawerBleeding}
        disableSwipeToOpen={false}
        ModalProps={{
            keepMounted: pendingBets.length !== 0,
        }}
        sx={{
            '& .MuiPaper-root': {
                height: `calc(70% - ${drawerBleeding}px)`,
                overflow: 'visible',
            }
        }}
        >
        <StyledStack
        justifyContent="center"
            sx={{
            position: 'absolute',
            top: -drawerBleeding,
            borderTopLeftRadius: 8,
            borderTopRightRadius: 8,
            visibility: 'visible',
            right: 0,
            left: 0,
            }}
        >
        <Puller />
        <Typography sx={{ p: 2, color: 'text.secondary',width: '100%',textAlign: 'left' }}>{pendingBets.length} bet{pendingBets.length !== 1 && "s"}</Typography>
        </StyledStack>
        <StyledStack justifyContent="space-between" sx={{ px: 2, pb: 1, height: '100%', overflow: 'auto' }}>
                <BetDisplay
                    pendingBets={pendingBets}
                    setPendingBets={setPendingBets}
                    renderToggle={renderToggle}
                    setRenderToggle={setRenderToggle}
                />
                <TrackButton 
                pendingBets={pendingBets} 
                setPendingBets={setPendingBets}
                renderToggle={renderToggle} 
                modelType={modelType}
                setSuccessSnackbarOpen={setSuccessSnackbarOpen}
                setErrorSnackbarOpen={setErrorSnackbarOpen}
                setErrorSnackbarMessage={setErrorSnackbarMessage}
                />
        </StyledStack>
        </SwipeableDrawer>
        </Root>

        <SuccessSnackbar 
        open={successSnackbarOpen} 
        setOpen={setSuccessSnackbarOpen} 
        message={"Bets tracked successfully"} 
        />
        <ErrorSnackbar 
        open={errorSnackbarOpen} 
        setOpen={setErrorSnackbarOpen} 
        message={errorSnackbarMessage} 
        />
        </>
    );
}