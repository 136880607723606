import {useState,useEffect} from 'react';

import {
    Stack
} from '@mui/material';

import dayjs from 'dayjs';

import { MultiSelect, SingleSelect } from '../../../../components/menus';

export function FilterMenu({setFilteredBets,allBets,trackedModels}){

    // Time Filter
    const availableDateFilters = ['All-Time','Last 30','Last 7','Today'];
    const [dateFilter,setDateFilter] = useState(availableDateFilters[0]);
    const timeFilterFn = ({start_time}) => {
        switch (dateFilter) {
            case 'All-Time':
                return true;
            case 'Last 30':
                return dayjs(start_time).isAfter(dayjs().subtract(30,'day'));
            case 'Last 7':
                return dayjs(start_time).isAfter(dayjs().subtract(7,'day'))
            case 'Today':
                return dayjs(start_time).isAfter(dayjs().subtract(1,'day'))
            default:
                return true;
        }
    }

    // Model Filter
    const availableModels = trackedModels.map((items) => items.model_id);
    const availableModelsDisplay = trackedModels.map((items) => items.model_name);
    const [modelFilter,setModelFilter] = useState(availableModels);
    const modelFilterFn = ({model_id}) => modelFilter.includes(model_id);
    
    useEffect(() => {
        if (allBets.length !== 0) {
            let filteredData = allBets.slice().filter(timeFilterFn);
            filteredData = filteredData.filter(modelFilterFn);
            setFilteredBets(filteredData);
        }
     },[dateFilter,modelFilter,allBets]);

    return (
        <Stack direction="row" spacing={1}>
            <SingleSelect
            label={null}
            value={dateFilter}
            availableValues={availableDateFilters}
            onChangeFn={(event) => setDateFilter(event.target.value)}
            width={150}
            size='small'
            />
            <MultiSelect
            label={"Models"}
            values={modelFilter}
            setValues={setModelFilter}
            availableValues={availableModels}
            availableValuesDisplay={availableModelsDisplay}
            width={150}
            size='small'
            />
        </Stack>
    )
}