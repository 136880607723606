import Cookies from 'js-cookie';
import axios from 'axios';

export const FlaskClient = axios.create({
    baseURL: process.env.REACT_APP_API_URL + '/api/v1',
    timeout: 50000, // ms
    headers: {
        'Content-Type': 'application/json'
    },
    withCredentials: 'same-origin',
  });

FlaskClient.interceptors.request.use(
  (config) => {
    // Set cookies or auth tokens here
    config.headers['X-CSRFToken'] = Cookies.get('X-CSRF');
    return config;
  }, 
  (error) => {
    
  }
);

FlaskClient.interceptors.response.use(
    (response) => {
        return response;
    }, 
    (error) => {
      // Handle no response by filling in error response
      // as a 500 http status and empty data
      if (!error.response) {
        error.response = {status: 500, data: {}};
      };

      return Promise.reject(error);
    }
);