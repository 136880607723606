import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import {
    Grid,
    Stack
} from '@mui/material';

import { TrackingSummary,ProfitChart } from './common';
import { FilterMenu } from './filters';
import { LatestBets } from './latest-bets';
import { DashboardGridItem,DashboardGridItemTitleTypography,ExportLatestBetsButton } from './common';

export function TrackingDashboard({allBets,setAllBets,filteredBets,setFilteredBets,trackedModels,downloadLatestBetsAllowed,loading}){
    
    const theme = useTheme();
    const largeScreen = useMediaQuery(theme.breakpoints.up('md'));

    return (
        <Grid 
        direction={largeScreen ? 'row' : 'column'} 
        container
        >

            <DashboardGridItem xs='auto' md={7} lg={8} loading={loading}>
                <Stack direction='column' alignItems="flex-start" justifyContent="space-between" 
                spacing={2} height="100%" width="100%">
                    <FilterMenu 
                    setFilteredBets={setFilteredBets} 
                    allBets={allBets} 
                    trackedModels={trackedModels}
                    />
                    <TrackingSummary filteredBets={filteredBets}/>
                    <ProfitChart filteredBets={filteredBets} />
                </Stack>
            </DashboardGridItem>
            <DashboardGridItem xs loading={loading}>
                <DashboardGridItemTitleTypography
                actionButton={
                    // This is kind of lazy but I am only allowing exporting latest bets as csv
                    // on desktop. It doesn't seem worth it to implement additional logic for the super rare
                    // case of someone exporting a csv on their phone
                    downloadLatestBetsAllowed && largeScreen && <ExportLatestBetsButton allBets={allBets} />
                }
                >
                    Latest Bets
                </DashboardGridItemTitleTypography>
                <LatestBets
                allBets={allBets}
                allBetsLoading={loading}
                />
            </DashboardGridItem>

        </Grid>
    )
}