
import { useEffect,useState } from 'react';
import { 
    Container,
    Grid,
    Typography
} from '@mui/material';

import dayjs from 'dayjs';

import { blogArticlesRequest } from '../../../hooks/blog-api';
import { ResponsiveContainer } from '../../../components/responsive';
import { ArticleCard, ArticlesLoadingDisplay } from '../components/article-card';
import { ErrorDisplay } from '../../../components/status';
import { SeoHelmet } from '../../../components/seo';
import { descendingSortFunction } from '../../../utils/sorting';


export default function BlogMainPage(){
    
    const [articles,setArticles] = useState([]);
    const [loading,setLoading] = useState(true);
    const [error,setError] = useState(null);

    const handleResponse = (data) => {
        
        // Sort in query stopped working randomly so manually
        // sort by date_updated here
        const sortedArticles = data.slice().sort(
            (a,b) => 
            descendingSortFunction(dayjs(a.date_updated).unix(),dayjs(b.date_updated).unix())
        );
        setArticles(sortedArticles);
        setLoading(false);
    }

    useEffect(() => {   
        blogArticlesRequest(handleResponse,handleError);
    }, []);

    const handleError = (error) => {
        setError("Something went wrong fetching articles");
        setLoading(false);
    }

    return (
        <>
        <SeoHelmet 
        title="Blog"
        description="Daily betting previews for spreads, totals, and player props. Expert picks, and analytical deep 
        dives on NFL, NBA, College Football and College Basketball."
        />
        <ResponsiveContainer>
            <Container maxWidth="md">
                <Grid 
                container 
                direction="row" 
                justifyContent="flex-start"
                >
                    {!loading && error !== null && <ErrorDisplay error={error}/>}
                    
                    {!loading && error === null && articles.length === 0 &&
                    <Typography variant="h5" textAlign="center">
                        No articles yet
                    </Typography>
                    }

                    {
                    loading ?
                    <ArticlesLoadingDisplay />
                    :
                    articles.map((item,index) => (
                    <Grid item key={index} xs={12} md={6} sx={{p: 2}}>
                        <ArticleCard
                        key={index}
                        id={item.id}
                        title={item.title}
                        date={item.date_updated ? item.date_updated : item.date_created}
                        preview={item.preview}
                        />
                    </Grid>
                    ))}

                </Grid>
            </Container>
        </ResponsiveContainer>
        </>
    );
}

