
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Box,
    Button,
    Card,
    CardContent,
    CardHeader,
    Container,
    Divider,
    Grid,
    List,
    ListItem,
    ListItemText,
    Stack,
    Typography
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';

import dayjs from 'dayjs';
import { ChangelogGraphic } from '../../../components/graphics/changelog';
import { HyperparametersTutorialButton } from '../../../components/buttons';

export function Changelog(){

    return (
        <Container maxWidth="md">
            <Stack direction="column" spacing={4} divider={<Divider />}>
                <Typography variant="h3">Changelog</Typography>
                {changelog_data.map((item,index) => (
                    <LogContent 
                    key={index}
                    date={item.date}
                    imageSrc={item.imageSrc}
                    title={item.title}
                    details={item.details}
                    />
                ))}
            </Stack>
        </Container>
    )
}

function AdditionalMetricsGridItem({league,numberAdded,numberRemoved,highlights}){

    return (
        <Grid item xs={12} md={6} sx={{pl:1, pr: 1}}>
            <Card sx={{borderRadius: 2}}>
                <CardHeader
                    title={league}
                    titleTypographyProps={{variant: "h6"}}
                    subheader={highlights}
                    subheaderTypographyProps={{
                            height: 100
                    }}
                />

                <CardContent>
                    <Stack direction="row" alignItems="center" justifyContent="center" spacing={3}>
                        <Typography color="success.dark" variant="h5">+ {numberAdded}</Typography>
                        <Typography color="error.dark" variant="h5">- {numberRemoved}</Typography>
                    </Stack>
                </CardContent>
            </Card>
        </Grid>
    )
}

const additionMetricsDetails = (
    <Stack direction="column" spacing={2}>
        <Typography variant="body">
            Based on user feedback, our team improved our offering of statistics for all leagues. We added a multitude
            of new metrics, and removed some duplicate and unnecessary ones. The majority of NFL and NCAAF stats 
            that we added were defensive versions of offensive metrics that we already offer, and this led to a HUGE
            improvment in these new models' performance.
        </Typography>
        <Typography variant="body">
            See below for details on how each league was effected. Additionally, we improved our 
            FAQs to better explain what an Opponent stat means.
        </Typography>
        <Grid container rowSpacing={2} justifyContent="flex-start">
            <AdditionalMetricsGridItem 
            league={"NFL"}
            numberAdded={45}
            numberRemoved={5}
            highlights={"Added Point Differential, Defensive Third and Fourth Down Efficiencies, \
                Defensive Goal to Go Efficiencies, and Kickoff Average Field Position."}
            />
            <AdditionalMetricsGridItem 
            league={"NBA"}
            numberAdded={28}
            numberRemoved={1}
            highlights={"The offensive and defensive Four Factors are now available. Use the 'Four Factors' category filter \
                to easily find them when creating a model."}
            />
            <AdditionalMetricsGridItem 
            league={"NCAAF"}
            numberAdded={25}
            numberRemoved={5}
            highlights={"Added Point Differential, and defensive equivalents for offensive metrics that were missing."}
            />
            <AdditionalMetricsGridItem 
            league={"NCAAB"}
            numberAdded={16}
            numberRemoved={0}
            highlights={"The offensive and defensive Four Factors are now available. Use the 'Four Factors' category filter \
                to easily find them when creating a model."}
            />
        </Grid>
    </Stack>
)

const ModelEditingDetails = (
    <List sx={{listStyle: 'disc', pl: 2}}>
        <ListItem sx={{ display: "list-item" }}>
            <ListItemText 
            primary="Tweak and improve your models by using the new Edit feature from your profile or the model summary page." 
            />
            <Stack direction="row" alignItems="center" justifyContent="center" width="100%">
                <ChangelogGraphic file='./ProfileEditExample.png' width="100%" maxWidth={{xs:300,md:400}} />
            </Stack>
        </ListItem>
        <ListItem sx={{ display: "list-item" }}>
            <ListItemText 
            primary="Reduced the effects of outliers to further optimize team models. Silently re-trained all existing team models to
            include this optimization." 
            />
        </ListItem>
        <ListItem sx={{ display: "list-item" }}>
            <ListItemText 
            primary="Added stat count display when creating a model, and on summary page." 
            />
        </ListItem>
    </List>
)

function EmphasisBody(props){

    const {sx,...otherProps} = props;

    return (
        <Typography variant="body" sx={{fontWeight: 'bold', ...sx}} {...otherProps}>
            {props.children}
        </Typography>
    )
}

const technicalOverviewSxProps = {fontWeight: 'bold',color:"secondary.main"};
const PredictionBalancingDetails = (
    <List sx={{listStyle: 'disc', pl: 2}}>
        <ListItem sx={{ display: "list-item" }}>
            <ListItemText 
            primary="Overhauled our feature engineering to balance the predictions of team and player models. 
            Our old approach was leading to a bit of separation between training results and test results. As with all 
            of our updates, all effected existing models have been silently re-trained to include this optimization. See below
            for technical details." 
            />
        </ListItem>
        <ListItem sx={{ display: "list-item" }}>
            <ListItemText 
            primary="Updated our NFL and NBA expert team models. These will continued to be tuned as we add more customization options." 
            />
        </ListItem>
        <ListItem >
            <Box component="div" sx={{width: '100%'}}>
                <Accordion>
                    <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    >
                    Expand for Technical Overview
                    </AccordionSummary>
                    <Divider orientation="horizontal" flexItem />
                    <AccordionDetails>
                        <Stack direction="column" spacing={3}>

                            <Stack direction="column" spacing={1}>
                                <Typography variant="h5" sx={technicalOverviewSxProps}>Regularization</Typography>
                                <Typography variant="body">
                                    Utilized Kernel, Bias, and Activity Keras Layer regularizers with L1 and/or L2 regularization
                                    to prevent overfitting.
                                </Typography>
                                <Box>
                                    <Button href="https://keras.io/api/layers/regularizers" target="_blank" endIcon={<OpenInNewIcon />}>
                                        Reference
                                    </Button>
                                </Box>
                            </Stack>

                            <Stack direction="column" spacing={1}>
                                <Typography variant="h5" sx={technicalOverviewSxProps}>Normalization and Scaling</Typography>
                                <Typography variant="body">
                                    We are not going to cover any specifics here because we would rather not divulge our
                                    feature engineering strategy. We improved the distribution of our features through a new normalization
                                    approach which helped align training results to actual predictions.
                                </Typography>
                            </Stack>

                            <Stack direction="column" spacing={1}>
                                <Typography variant="h5" sx={technicalOverviewSxProps}>Loss Function</Typography>
                                <Typography variant="body">
                                    Prior to this update, we were using <EmphasisBody>Mean Square Error (MSE)</EmphasisBody> as our loss function for all models. This loss 
                                    function is robust, but can be sensitive to outliers. Since there are plenty outliers in sports data, 
                                    we are now using <EmphasisBody>Huber</EmphasisBody> as our loss function. Huber loss essentially uses MSE up until a certain residual threshold, and
                                    then uses <EmphasisBody>Mean Absolute Error (MAE)</EmphasisBody> beyond that threshold to reduce the impact of large residuals on the model's weights and biases. 
                                    And in our testing, we have found this to improve performance.
                                </Typography>
                            </Stack>

                        </Stack>
                    </AccordionDetails>
                </Accordion>
            </Box>
        </ListItem>
    </List>
)

const CustomHyperparametersDetails = (
    <Stack direction="column" alignItems="flex-start" justifyContent="center" width="100%" spacing={2}>
        <Typography variant="body">
            Use custom machine learning hyperparameters to set individual layer parameters, epochs, 
            learning rate, and more when creating your models. These are advanced controls, so if you
            are not familiar with the terminology, we recommend to use the defaults, or use the below link
            to a brief tutorial on custom hyperparameters.
        </Typography>
        <Box>
            <HyperparametersTutorialButton />
        </Box>
        <Typography variant="body">
            Available customizations can be found below the stat selection table when creating a model.
        </Typography>
        <Stack direction="column" alignItems="center" justifyContent="center" width="100%">
            <ChangelogGraphic file='./CustomHyperparameters.png' width="100%" maxWidth={{xs:300,md:500}} />
        </Stack>
    </Stack>
)

const BetTrackingDetails = (
    <Stack direction="column" alignItems="flex-start" justifyContent="center" width="100%" spacing={2}>
        <Typography variant="body">
            Track team and prop bets right from your model's predictions.
        </Typography>
    </Stack>
)

const changelog_data = [
    {
        date: dayjs('2024-12-23'),
        imageSrc: './BetTrackingExample.png',
        title: "Bet Tracking",
        details: BetTrackingDetails
    },
    {
        date: dayjs('2024-11-11'),
        imageSrc: null,
        title: "Custom Hyperparameters",
        details: CustomHyperparametersDetails
    },
    {
        date: dayjs('2024-10-30'),
        imageSrc: null,
        title: "Prediction Balancing",
        details: PredictionBalancingDetails
    },
    {
        date: dayjs('2024-10-05'),
        imageSrc: null,
        title: "Model Editing and Team Model Optimization",
        details: ModelEditingDetails
    },
    {
        date: dayjs('2024-09-19'),
        imageSrc: null,
        title: "Additional Metrics",
        details: additionMetricsDetails
    },
    {
        date: dayjs('2024-09-09'),
        imageSrc: null,
        title: "Site Launch",
        details: <Typography variant="body"></Typography>
    },
]


// details should be a react component, the default would be
// to use Typpography variant=body
function LogContent({date,imageSrc,title,details}){

    return (
        <Stack direction={{xs:"column",sm:"row"}} spacing={{xs:2, sm:5}} alignItems="flex-start" >
            <Typography color="textDisabled" sx={{minWidth: 120}}>{date.format('MMM Do, YYYY')}</Typography>

            <Stack direction="column" spacing={2} width="100%">
                <Typography variant="h4">{title}</Typography>
                {imageSrc &&
                <ChangelogGraphic
                file={imageSrc} 
                width="100%"
                />
                }
                {details}
            </Stack>
        </Stack>
    )
}