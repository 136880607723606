// Common components used across multiple pages and features
// in relation to bet tracking
//
// All display components, minimal logic
import {
    Stack,
    Typography
} from '@mui/material';

import dayjs from 'dayjs';

import {TeamLogo} from '../components/graphics/team-logo';
import { MobileSportsbookLogo } from './graphics/sportsbook-logo';
import { leagueKeyToDisplay } from '../config/leagues';

function TeamMatchupTypography(
    {league,start_time,away,away_display_name_abbreviated,home,home_display_name_abbreviated,away_score,home_score,stackPropOverrides={}}
){
    // Display in local timezone
    const startTime = dayjs(start_time).format('MMM Do, LT');

    return (
        <Stack direction='row' spacing={1} alignItems="center" justifyContent="space-between" width="100%" {...stackPropOverrides}>
            <Stack direction="row" spacing={1} alignItems="center">
                <Stack direction="row" spacing={0.5} alignItems="center">
                    <TeamLogo team={away} league={league} width={20}/>
                    <Typography variant="subtitle2" sx={{color: 'text.secondary'}}>{away_display_name_abbreviated}</Typography>
                    {away_score != null && <Typography variant="subtitle2" sx={{color: 'text.secondary'}}>{away_score}</Typography>}
                </Stack>
                <Typography variant="subtitle2" sx={{color: 'text.secondary'}}>at</Typography>
                <Stack direction="row" spacing={0.5} alignItems="center">
                    <TeamLogo team={home} league={league} width={20}/>
                    <Typography variant="subtitle2" sx={{color: 'text.secondary'}}>{home_display_name_abbreviated}</Typography>
                    {home_score != null && <Typography variant="subtitle2" sx={{color: 'text.secondary'}}>{home_score}</Typography>}
                </Stack>
            </Stack>
            <Typography variant="subtitle2" sx={{color: 'text.secondary'}}>{startTime}</Typography>
        </Stack>
    )
}

function PlayerMatchupTypography(
    {league,prop,start_time,display_name,team,opponent,opponent_abbreviation,result,stackPropOverrides={}}
){

    // Display in local timezone
    const startTime = dayjs(start_time).format('MMM Do, LT');

    return (
        <Stack direction="column" spacing={1} width="100%">

        <Typography variant="subtitle2" sx={{color: 'text.secondary'}}>{result != null && result} {prop}</Typography>

        <Stack direction='row' spacing={1} alignItems="center" justifyContent="space-between" width="100%" {...stackPropOverrides}>
            <Stack direction="row" spacing={1} alignItems="center">
                <Stack direction="row" spacing={0.5} alignItems="center">
                    <TeamLogo team={team} league={league} width={20}/>
                    <Typography variant="subtitle2" sx={{color: 'text.secondary'}}>{display_name}</Typography>
                </Stack>
                <Typography variant="subtitle2" sx={{color: 'text.secondary'}}>vs</Typography>
                <Stack direction="row" spacing={0.5} alignItems="center">
                    <TeamLogo team={opponent} league={league} width={20}/>
                    <Typography variant="subtitle2" sx={{color: 'text.secondary'}}>{opponent_abbreviation}</Typography>
                </Stack>
            </Stack>
            <Typography variant="subtitle2" sx={{color: 'text.secondary'}}>{startTime}</Typography>
        </Stack>
        </Stack>
    )
}

export function MatchupTyporaphy({league,prop,matchup,stackPropOverrides={}}){

    const isTeamBet = matchup.type === 'team' ? true : false;

    // Hack to convert league IF backend for now until I cleanup league naming
    // convention
    const displayLeague = leagueKeyToDisplay(league);

    return (
        <>
        {isTeamBet ? 
            <TeamMatchupTypography 
            league={displayLeague}
            start_time={matchup.start_time}
            away={`${matchup.away.location} ${matchup.away.nickname}`}
            away_display_name_abbreviated={matchup.away.abbreviation}
            home={`${matchup.home.location} ${matchup.home.nickname}`}
            home_display_name_abbreviated={matchup.home.abbreviation}
            home_score={matchup.home.score}
            away_score={matchup.away.score}
            stackPropOverrides={stackPropOverrides}
            />
            :
            <PlayerMatchupTypography 
            league={displayLeague}
            start_time={matchup.start_time}
            prop={prop}
            display_name={`${matchup.player.first_name[0]}. ${matchup.player.last_name}`}
            team={`${matchup.team.location} ${matchup.team.nickname}`}
            opponent={`${matchup.opponent.location} ${matchup.opponent.nickname}`}
            opponent_abbreviation={matchup.opponent.abbreviation}
            result={matchup.player.result}
            stackPropOverrides={stackPropOverrides}
            />
        }
        </>
    )
}

export function CommonBetDescription({summary,price,sportsbook}){

    return (
        <Stack direction="row" spacing={1} alignItems="center">
            <Typography>{summary}</Typography>
            <Typography sx={{color: 'text.secondary'}}>{price}</Typography>
            <MobileSportsbookLogo sportsbook={sportsbook}/>
        </Stack>
    )
}