
import {SportsFootball, SportsBasketball} from '@mui/icons-material';

export const team_leagues = [
    "NFL",
    "NBA",
    "NCAA-Football",
    "NCAA-Basketball"
]

export const player_leagues = [
    "NFL",
    "NBA"
]

export const leagueConfig = {
    'nfl' : { id: 1,    display: 'NFL',     player_enabled: true},
    'nba' : { id: 2,    display: 'NBA',     player_enabled: true},
    'cfb' : { id: 3,    display: 'NCAAF',   player_enabled: false},
    'cbk' : { id: 4,    display: 'NCAAB',   player_enabled: false}
};

export const leagueKeyToDisplay = (key) => {
    return leagueConfig[key] ? leagueConfig[key].display : key;
}

export const leagueDisplayToKey = (display) => {
    let returnKey = null;
    Object.entries(leagueConfig).map(([key,leagueInfo]) => {
        if (display === leagueInfo.display) {
            returnKey = key;
        }
    });

    return returnKey;
}

export function LeagueIcon(props) {

    const {league,...iconProps} = props;

    const map = {
        "NFL": <SportsFootball {...iconProps} />,
        "NCAA-Football": <SportsFootball {...iconProps} />,
        "NBA": <SportsBasketball {...iconProps}/>,
        "NCAA-Basketball": <SportsBasketball {...iconProps} />
    }

    return map[league]
}

export const predictionWarningMonthsByLeague = {
    // Months are zero indexed so January = 0 to be used
    // to compared to dayjs().month()
    "NFL"               : [
        9
    ],
    "NBA"               : [
        10
    ],
    "NCAAF"     : [
        9
    ],
    "NCAAB"   : [
        10
    ]
}

export const leagueToGlossaryIdMap = {
    'NFL' : 1,
    'NCAAF' : 2,
    'NBA' : 3,
    'NCAAB' : 4,

}