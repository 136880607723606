
import { FlaskClient } from "./setup";

export const teamTrainRequest = (requestBody,params,handleResponse,handleError) => {
    FlaskClient.post('team/models/train',
      requestBody,
      {
        params: params
      }
    )
    .then((response) => {
        handleResponse(response);
    })
    .catch((error) => {
        handleError(error);
    });
}

export const teamPredictionsRequest = (params,handleResponse,handleError) => {
  FlaskClient.get('team/models/predictions',
  {
    params: params
  }
  )
  .then((response) => {
      handleResponse(response);
  })
  .catch((error) => {
      handleError(error);
  });
}

export const teamSummaryRequest = (handleResponse,handleError) => {
  FlaskClient.get('team/models/summary')
  .then((response) => {
      handleResponse(response);
  })
  .catch((error) => {
      handleError(error);
  });
}

export const teamSlimSummaryRequest = (handleResponse,handleError) => {
  FlaskClient.get('team/models/summary',
  {
  params: {slim: true}
  }
  )
  .then((response) => {
      handleResponse(response);
  })
  .catch((error) => {
      handleError(error);
  });
}

export const teamDeleteRequest = (params,handleResponse,handleError) => {
  FlaskClient.delete('team/models/delete',
  {
  params: params
  }
  )
  .then((response) => {
      handleResponse(response);
  })
  .catch((error) => {
      handleError(error);
  });
}

export const teamStoreRequest = (params,handleResponse,handleError) => {
  FlaskClient.post('team/models/store',
    null,
    {
      params: params
    }
  )
  .then((response) => {
      handleResponse(response);
  })
  .catch((error) => {
      handleError(error);
  });
}

export const teamExpertRequest = (params,handleResponse,handleError) => {
  FlaskClient.put('team/models/expert',
    null,
    {
      params: params
    }
  )
  .then((response) => {
      handleResponse(response);
  })
  .catch((error) => {
      handleError(error);
  });
}

export const teamScheduleRequest = (params,handleResponse,handleError) => {
  FlaskClient.get('team/models/schedule',
  {
      params: params
  }
  )
  .then((response) => {
      handleResponse(response);
  })
  .catch((error) => {
      handleError(error);
  });
}

export const teamUpdateRequest = (params,handleResponse,handleError) => {
  FlaskClient.put('team/models/update',
    null,
    {
      params: params
    }
  )
  .then((response) => {
      handleResponse(response);
  })
  .catch((error) => {
      handleError(error);
  });
}

export const teamSaveRequest = (params,handleResponse,handleError) => {
  FlaskClient.post('team/models/save',
    null,
    {
      params: params
    }
  )
  .then((response) => {
      handleResponse(response);
  })
  .catch((error) => {
      handleError(error);
  });
}

export const teamModelsRequest = (handleResponse,handleError) => {
  FlaskClient.get('team/models/')
  .then((response) => {
      handleResponse(response);
  })
  .catch((error) => {
      handleError(error);
  });
}

export const teamStatisticsRequest = (params,handleResponse,handleError) => {
  FlaskClient.get('team/models/statistics',
  {
    params: params
  }
  )
  .then((response) => {
      handleResponse(response);
  })
  .catch((error) => {
      handleError(error);
  });
}

export const teamValidDatesRequest = (params,handleResponse,handleError) => {
  FlaskClient.get('team/models/valid-dates',
  {
    params: params
  }
  )
  .then((response) => {
      handleResponse(response);
  })
  .catch((error) => {
      handleError(error);
  });
}

export const teamHyperparametersRequest = (params,handleResponse,handleError) => {
  FlaskClient.get('team/models/hyperparameters',
  {
    params: params
  }
  )
  .then((response) => {
      handleResponse(response);
  })
  .catch((error) => {
      handleError(error);
  });
}

export const teamModelTrackingRequest = (params,handleResponse,handleError) => {
  FlaskClient.put('team/models/tracking',
  null,
  {
    params: params
  }
  )
  .then((response) => {
      handleResponse(response);
  })
  .catch((error) => {
      handleError(error);
  });
}

export const teamBetsPostRequest = (jsonBody,handleResponse,handleError) => {
  FlaskClient.post('team/bets/',
    jsonBody,null
  )
  .then((response) => {
      handleResponse(response);
  })
  .catch((error) => {
      handleError(error);
  });
}

export const teamBetsGetRequest = (params,handleResponse,handleError) => {
  FlaskClient.get('team/bets/',
  {
    params: params
  }
  )
  .then((response) => {
      handleResponse(response);
  })
  .catch((error) => {
      handleError(error);
  });
}

export const teamBetsLatestRequest = (jsonBody,handleResponse,handleError) => {
  FlaskClient.put('team/bets/latest',
  jsonBody,null
  )
  .then((response) => {
      handleResponse(response);
  })
  .catch((error) => {
      handleError(error);
  });
}

export const teamBetDeleteRequest = (params,handleResponse,handleError) => {
  FlaskClient.delete('team/bets/',
  {
  params: params
  }
  )
  .then((response) => {
      handleResponse(response);
  })
  .catch((error) => {
      handleError(error);
  });
}