import {useEffect, useState} from 'react';
import {
    Container,
} from '@mui/material';

import { TrackingDashboard } from './dashboard';
import { teamBetsGetRequest } from '../../../../hooks/api/team'

export default function TeamTrackingContainer() {

    const [allBets,setAllBets] = useState([]);
    const [filteredBets,setFilteredBets] = useState([]);
    const [trackedModels,setTrackedModels] = useState([]);
    const [downloadLatestBetsAllowed,setDownloadLatestBetsAllowed] = useState(false);
    const [loading,setLoading] = useState(true);

    const handleResponse = (response) => {
      
      const bets = response.data.bets
      setAllBets(bets);
      setFilteredBets(bets);

      setTrackedModels(response.data.tracked_models);

      setDownloadLatestBetsAllowed(response.data.download_latest_bets_allowed);

      setLoading(false);
    }

    const handleError = (error) => {
      setLoading(false);
    }

    useEffect(()=> {
      setLoading(true);
      const params = {};
      teamBetsGetRequest(params,handleResponse,handleError);
    },[]);

    return (
        <Container maxWidth="lg" disableGutters>
            <TrackingDashboard
            allBets={allBets}
            setAllBets={setAllBets}
            filteredBets={filteredBets}
            setFilteredBets={setFilteredBets}
            trackedModels={trackedModels}
            downloadLatestBetsAllowed={downloadLatestBetsAllowed}
            loading={loading}
            />
        </Container>
    );
};