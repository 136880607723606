import {useEffect,useState} from 'react';
import { 
    Stack,
    Typography
} from '@mui/material';

import {useNavigate} from 'react-router-dom';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
// Extend with plugins
dayjs.extend(utc)
dayjs.extend(timezone);

const EST = 'America/New_York';

import { PlayerPredictionResultsTable } from "./player-prediction-results-table";
import { playerPredictionsRequest } from '../../../../hooks/api/player';
import { PlayerPredictionResultsTutorialPopupButton } from './tutorial';

export function PlayerPredictionResultsContainer({league,position,prop,date}) {
    // date is just an ISO string. The way dayjs works is that TZ EST needs
    // to be applied when you create a dayjs object or else it will automatically
    // convert it to local time. This the constant use of .tz(EST) for now.

    const navigate = useNavigate();

    const [predictionsData,setPredictionsData] = useState([]);
    const [units,setUnits] = useState("");
    const [loading,setLoading] = useState(true);

    const handleResponse = (response) => {
      setPredictionsData(response.data.predictions_data);
      setUnits(response.data.units);
      setLoading(false);
    }
    const handleError = (error) => {
      setLoading(false);
      if (error.response) {
        
        // CSRF rejection = 400
        // Model not found in session = 404
        if (error.response.data.csrf || error.response.status === 404) {
            navigate('/');
        }
        
        // 500
        else {
            navigate('/');
        }
      }
      // No response
      else {
          navigate('/');
      }
      }

    useEffect(() => {
        const params = {
          'league'    : league,
          'position'  : position,
          'prop'      : prop,
          'date'      : dayjs(date).tz(EST).format('YYYYMMDD')
        }

        playerPredictionsRequest(params,handleResponse,handleError);
          
      }, []);

    return (
        <>
          <Stack direction="column" justifyContent="center" alignItems="center" sx={{pb: 5}}>
            {loading ? (
              <>
                <Typography variant="h5">
                  Generating {prop} predictions for all {position}s on {dayjs(date).tz(EST).format("MMM D, YYYY")}
                </Typography>
              </>

            ) : (
              <> 
                <Typography variant="h5" gutterBottom>{prop} Predictions for {position}s</Typography>
              </>
            )}
            <Stack direction="row" justifyContent="flex-end" sx={{width: "100%"}}>
                <PlayerPredictionResultsTutorialPopupButton />
            </Stack>
            <PlayerPredictionResultsTable league={league} position={position} prop={prop} date={date}
              predictionData={predictionsData} units={units} loading={loading} />
          </Stack>
        </>
    )
}