import { FREE_TRIAL_DAYS } from "./account";


// Source Notes
// Most of the feature definitions are generic explanations 
// of our offerings BUT there are some specific ones such 
// as the amount of models a user can save. Refer to below
// for locations where those definitions are found in the backend
//
// Saved Model Limit
//      File: flask-api/config/featurePermissions/py
//      Summary: The class variable MAXIMUM_SAVED_MODELS determines 
//          the maximum saved models based on tier.
//
// Historical Profitability
//      File: flask-api/config/featurePermissions/py
//      Summary: The class variable HISTORICAL_PROFITABILITY determines 
//          if a user has access to historical profitability
//


export const freeFeatures = [
    {
      "feature": `Pro features for ${FREE_TRIAL_DAYS} day trial`,
      "enabled": true
    },
    {
      "feature": "NO credit card required",
      "enabled": true
    }
];
  
export const proFeatures = [
    {
      "feature": "Create unlimited team models",
      "enabled": true
    },
    {
      "feature": "Create unlimited player prop models",
      "enabled": true
    },
    {
      "feature": "Historical profitability",
      "enabled": false
    },
    {
      "feature": "Model performance grade",
      "enabled": true
    },
    {
      "feature": "Access to expert models",
      "enabled": false
    },
    {
      "feature": "Save up to 25 models",
      "enabled": true
    },
    {
      "feature": "Future predictions",
      "enabled": true
    },
    {
      "feature": "Historical predictions",
      "enabled": true
    },
    {
      "feature": "Edit models",
      "enabled": true
    },
    {
      "feature": "Set model training hyperparameters",
      "enabled": false
    },
    {
      "feature": "Bet tracking",
      "enabled": true
    },
];

export const proComingSoonFeatures = [
  {
    "feature": "Automated bet tracking",
    "enabled": false
  },
  {
    "feature": "Injury adjustments",
    "enabled": true
  },
  {
    "feature": "Leaderboard",
    "enabled": true
  }
]
  
export const premiumFeatures = [
    {
      "feature": "Create unlimited team models",
      "enabled": true
    },
    {
      "feature": "Create unlimited player prop models",
      "enabled": true
    },
    {
      "feature": "Historical profitability",
      "enabled": true
    },
    {
      "feature": "Model performance grade",
      "enabled": true
    },
    {
      "feature": "Access to expert models",
      "enabled": true
    },
    {
      "feature": "Save up to 50 models",
      "enabled": true
    },
    {
      "feature": "Future predictions",
      "enabled": true
    },
    {
      "feature": "Historical predictions",
      "enabled": true
    },
    {
      "feature": "Edit models",
      "enabled": true
    },
    {
      "feature": "Set model training hyperparameters",
      "enabled": true
    },
    {
      "feature": "Bet tracking",
      "enabled": true
    },
];

export const premiumComingSoonFeatures = [
  {
    "feature": "Automated bet tracking",
    "enabled": true
  },
  {
    "feature": "Injury adjustments",
    "enabled": true
  },
  {
    "feature": "Leaderboard",
    "enabled": true
  }
]

// The strings match the backend defitinion in
// flask-api/config/stripe.py StripeLiveProductsEnum/StripeTestProductsEnum
// in the order field in the enums. These will be the names returned by
// the /subscription endpoint
export const tierFeatureMap = {
    'PRO'           : proFeatures,
    'PREMIUM'       : premiumFeatures
}

export const tierComingSoonFeatureMap = {
  'PRO'           : proComingSoonFeatures,
  'PREMIUM'       : premiumComingSoonFeatures
}