import {forwardRef,useState} from 'react';
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    IconButton,
    ListItemIcon,
    ListItemText,
    MenuItem,
    Slide,
    Stack,
    Typography
} from '@mui/material';

import CheckIcon from '@mui/icons-material/Check';

import { styled,useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

import TuneIcon from '@mui/icons-material/Tune';
import CloseIcon from '@mui/icons-material/Close';
import SwapVertIcon from '@mui/icons-material/SwapVert';
import { DropdownMenu } from './menus';

const Transition = forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
  });


const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    // Control entire popop window styling
    '& .MuiPaper-root': {
        backgroundColor: theme.palette.background.default,
        borderRadius: 5,
        height: '90vh'
    }
}));

export function FilterButton({filterComponents,handleReset}){

    const [open,setOpen] = useState(false);

    return(
        <>
            <Button onClick={() => {setOpen(true)}}
            startIcon={<TuneIcon />}
            >
                Filter
            </Button>
            <FullScreenFilterContainer open={open} setOpen={setOpen} 
                filterComponents={filterComponents} handleReset={handleReset} />
        </>
    )
}

export function FullScreenFilterContainer({open,setOpen,filterComponents,handleReset}){

    function handleClose() {setOpen(false)}

    return (
        <>
        <BootstrapDialog
            fullScreen
            open={open}
            onClose={handleClose}
            TransitionComponent={Transition}
        >
            <DialogTitle sx={{p: 1}}>
                <Stack direction="row" width="100%" alignItems="center" justifyContent="space-between">

                <IconButton
                onClick={handleClose}
                >
                    <CloseIcon />
                </IconButton>

                <Typography variant="h6">
                    Filter & Sort
                </Typography>

                <Button onClick={handleReset}>
                    Reset
                </Button>

                </Stack>
            </DialogTitle>
            
            <DialogContent>
                {filterComponents}
            </DialogContent>
            <DialogActions sx={{justifyContent:'center'}}>
                <Button onClick={handleClose}>Confirm</Button>
            </DialogActions>
        </BootstrapDialog>
        </>
    )
}

export function FilterMenu(props){

    const theme = useTheme();
    const largeScreen = useMediaQuery(theme.breakpoints.up('md'));

    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);

    const handleClose = () => {
        setAnchorEl(null);
    };

    return (
        <>
        <Button onClick={(event) => {setAnchorEl(event.currentTarget)}}
            startIcon={largeScreen && <TuneIcon />}
            >
            {largeScreen ?  "Filter" : <TuneIcon />}
        </Button>
        <DropdownMenu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        sx={{
            '& .MuiPaper-root': {
                mt: 1,
                minWidth: 200
            }
        }}
        >
            {props.children}
        </DropdownMenu>
        </>
    )
}

export function SortByMenu(props){

    // options - List of dictionaries defining the title and the sortFn
    // Example: options = [
    // { title: Highest Grade, sortFn: descendingGradeSortFn }
    // ]

    const {selectedIndex,options,onChangeFn,...otherProps} = props;

    const theme = useTheme();
    const largeScreen = useMediaQuery(theme.breakpoints.up('md'));

    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);

    const handleClose = () => {
        setAnchorEl(null);
    };

    return (
        <>
        <Button onClick={(event) => {setAnchorEl(event.currentTarget)}}
            startIcon={largeScreen && <SwapVertIcon />}
            >
            {largeScreen ? "Sort" : <SwapVertIcon /> }
        </Button>
        <DropdownMenu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        sx={{
            '& .MuiPaper-root': {
                mt: 1,
                minWidth: 200
            }
        }}
        >
            {options.map((option, index) => (
                <MenuItem
                    key={option.title}
                    selected={index === selectedIndex}
                    value={index}
                    onClick={onChangeFn}
                >
                    <ListItemText primary={option.title} />
                    {index === selectedIndex &&
                    <ListItemIcon sx={{justifyContent: 'flex-end'}}>
                        <CheckIcon fontSize="small" color="success"/>
                    </ListItemIcon>
                    }
                </MenuItem>
            ))}
        </DropdownMenu>
        </>
    )
}