import React from 'react';
import {
    Grid,
    Box,
    Button,
    Typography
} from '@mui/material';
import { Link } from 'react-router-dom';

class ErrorBoundary extends React.Component {
    constructor(props) {
        super(props);
        this.state = { hasError: false };
    }

    static getDerivedStateFromError(error) {
        return { hasError: true };
    }

    componentDidCatch(error, errorInfo) {
        console.error("Error caught in Error Boundary:", error, errorInfo);
    }

    render() {
        if (this.state.hasError) {
            return (
                <>
                    <Grid container direction="column" alignItems="center" spacing={0} p={5}>
                        <Box>
                            <Typography variant="h4">You've Entered Unknown Territory...</Typography>
                        </Box>
                        <Box p={5}>
                            <Link to='/' style={{ textDecoration: 'none', color: 'inherit' }}>
                                <Button variant="contained">
                                    Back To Safety
                                </Button>
                            </Link>
                        </Box>
                    </Grid>
                </>
            )
        }

        return this.props.children;
    }
}

export default ErrorBoundary;