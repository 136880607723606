
export const USD_noSign = new Intl.NumberFormat('en-US', {
    style: 'decimal',
    trailingZeroDisplay: 'stripIfInteger',
    minimumFractionDigits: 2,
    maximumFractionDigits: 2
});

export const USD = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    trailingZeroDisplay: 'stripIfInteger'
});