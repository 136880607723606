import  {useState } from 'react';
import "react-circular-progressbar/dist/styles.css";

import {
    Box,
    Card,
    CardActions,
    CardContent,
    CardHeader,
    IconButton,
    ListItem,
    ListItemButton,
    ListItemIcon,
    ListItemText,
    Stack,
    Typography,
} from '@mui/material';

import MoreVertIcon from '@mui/icons-material/MoreVert';
import EditIcon from '@mui/icons-material/Edit';
import SaveAsIcon from '@mui/icons-material/SaveAs';
import DeleteIcon from '@mui/icons-material/Delete';
import AssessmentIcon from '@mui/icons-material/Assessment';

import dayjs from 'dayjs';

import { OverallGradeCircle } from '../../../../components/metric-displays';
import { ActionButton } from '../../../../components/buttons';
import { DropdownMenu } from '../../../../components/menus';
import { LeagueIcon } from '../../../../config/leagues';

export function ModelCard(
    {
        modelId,modelName,league,infoDisplay,created,overallGrade,trackingEnabled,
        handleEdit,handleRename,handleDelete,handleTracking,handleSummary,handlePredictions
    }
){

    const action = (
    <ModelActions 
    modelId={modelId}
    modelName={modelName}
    trackingEnabled={trackingEnabled}
    handleEdit={handleEdit}
    handleRename={handleRename} 
    handleDelete={handleDelete}
    handleTracking={handleTracking}
    />)

    return (
        
        <Card sx={{borderRadius: 2}}>
            <CardHeader
                avatar={<LeagueIcon league={league} sx={{fontSize:"2rem"}}/>}
                action={action}
                title={modelName}
                subheader={infoDisplay}
            />

            <CardContent>
                <Stack alignItems="center">
                    <Box sx={{height: 100,width: 100}}>
                        <OverallGradeCircle grade={overallGrade} />
                    </Box>
                </Stack>
            </CardContent>

            <CardActions>
                <Stack direction="column" spacing={1} width="100%">
                    <Stack direction="row" spacing={1} justifyContent="center" width="100%">
                        <ActionButton onClick={() => handleSummary(modelId)}>Summary</ActionButton>
                        <ActionButton onClick={() => handlePredictions(modelId)}>Predict</ActionButton>
                    </Stack>
                <Stack direction="row" justifyContent="space-between">
                    <Typography variant="caption">
                        {"Created: " + dayjs(created).format("LLL")}
                    </Typography>
                    {trackingEnabled &&
                    <Typography 
                    variant="caption" 
                    color="white" 
                    sx={{backgroundColor: 'secondary.dark',borderRadius: 4,px:1}}>
                        Tracking
                    </Typography>
                    }
                </Stack>
                </Stack>
                
            </CardActions>
        </Card>
    )
}

function ModelActions({modelId,modelName,trackingEnabled,handleEdit,handleRename,handleDelete,handleTracking}) {

    const handleActionsClicked = (event) => {
        event.preventDefault();
        setAnchorEl(event.currentTarget);
    }

    ////////////////////////////////////////////////////////
    // Menu setup
    ////////////////////////////////////////////////////////
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);
    const handleClose = () => {
        setAnchorEl(null);
        
    };

    return (
        <>
        <IconButton 
        aria-label="actions"
        onClick={handleActionsClicked}>
            <MoreVertIcon />
        </IconButton>

        <DropdownMenu
            id="basic-menu"
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            sx={{
                '& .MuiPaper-root': {
                    mt: 1
                }
            }}
        >   
            <ListItem key={"Edit"} disablePadding>
                <ListItemButton onClick={()=>{handleEdit(modelId);handleClose();}}>
                <ListItemIcon>
                    <EditIcon />
                </ListItemIcon>
                <ListItemText primary={"Edit"} />
                </ListItemButton>
            </ListItem>
            <ListItem key={"Rename"} disablePadding>
                <ListItemButton onClick={()=>{handleRename(modelId,modelName);handleClose();}}>
                <ListItemIcon>
                    <SaveAsIcon />
                </ListItemIcon>
                <ListItemText primary={"Rename"} />
                </ListItemButton>
            </ListItem>
            <ListItem key={"Delete"} disablePadding>
                <ListItemButton onClick={()=>{handleDelete(modelId);handleClose();}}>
                <ListItemIcon>
                    <DeleteIcon />
                </ListItemIcon>
                <ListItemText primary={"Delete"} />
                </ListItemButton>
            </ListItem>
            {/* <ListItem key={"Tracking"} disablePadding>
                <ListItemButton onClick={()=>{handleTracking(modelId,trackingEnabled);handleClose();}}>
                <ListItemIcon>
                    <AssessmentIcon />
                </ListItemIcon>
                <ListItemText primary={`${trackingEnabled ? "Untrack" : "Track"}`} />
                </ListItemButton>
            </ListItem> */}
        </DropdownMenu>

        </>
    )
}